import MIconButton from '@assets/mui/@material-extend/MIconButton';
import MySwitch from '@components/MyMaterialUI/MySwitch';
import { FormControlLabel } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocationSelector from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';

import { RouteWidgetBaseParameters, TransportRoutePointConditional } from '../../types';

// Свойства компонента
export interface LocationsProps {
  BaseData: RouteWidgetBaseParameters;
  primaryLangId: string; // Текущий основной язык
  secondaryLangId: string; // Текущий второстепенный язык
  error: boolean; // Текущий второстепенный язык

  startLocation: BranchItem | undefined;
  endLocation: BranchItem | undefined;
  startTransportingCondition: string;
  endTransportingCondition: string;

  // Обработчики изменений
  onChangeStartLocation: { (location: BranchItem | undefined): void };
  onChangeEndLocation: { (location: BranchItem | undefined): void };
  onChangeStartTransportingCondition: { (id: string, checked?: boolean): void };
  onChangeEndTransportingCondition: { (id: string, checked?: boolean): void };
  onSwapLocation: () => void;
}

export const swapEvent$ = new Subject<boolean>();

// Компонент для выбора локации отправления/назначения
const Locations = (props: LocationsProps) => {
  const {
    primaryLangId,
    secondaryLangId,
    error,
    startLocation,
    endLocation,
    onChangeStartLocation,
    onChangeEndLocation,
    startTransportingCondition,
    endTransportingCondition,
    onChangeStartTransportingCondition,
    onChangeEndTransportingCondition,
    BaseData,
    onSwapLocation,
  } = props;
  const { t } = useTranslation(['RoutesCalculationWidget', 'DeliveryForm']);
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const checkedStartCondition =
    BaseData.startTransportingConditions.find((condition) => condition.code === 'FCA')?.id || '';

  const checkedEndCondition =
    BaseData.endTransportingConditions.find((condition) => condition.code === 'LO')?.id || '';

  const currentStartCondition = (BaseData.startTransportingConditions.find(
    (condition) => condition.id === startTransportingCondition
  )?.code || '') as TransportRoutePointConditional;

  const currentEndCondition = (BaseData.endTransportingConditions.find(
    (condition) => condition.id === endTransportingCondition
  )?.code || '') as TransportRoutePointConditional;

  /**
   * Обработчик изменения начальных и конечных условий погрузки груза
   * @param mode
   * @param position
   * @param checked
   */
  const handleChangeTransportCondition = (
    mode: TransportRoutePointConditional,
    position: 'start' | 'end',
    checked?: boolean
  ) => {
    switch (position) {
      case 'start': {
        const id =
          BaseData.startTransportingConditions.find((condition) => condition.code === mode)?.id ||
          '';
        onChangeStartTransportingCondition(id, checked);
        break;
      }
      case 'end': {
        const id =
          BaseData.endTransportingConditions.find((condition) => condition.code === mode)?.id || '';
        onChangeEndTransportingCondition(id, checked);
        break;
      }
    }
  };

  /**
   * Обработчик изменения стартовой локации
   * @param location
   */
  const handleChangeStartLocation = (location: BranchItem | undefined) => {
    const isWarehouse = location?.type === 'location' && location.isWarehouse;

    onChangeStartLocation(location);
    handleChangeTransportCondition(isWarehouse ? 'DOOR' : 'FOB', 'start');
  };

  /**
   * Обработчик изменения конечной локации
   * @param location
   */
  const handleChangeEndLocation = (location: BranchItem | undefined) => {
    const isWarehouse = location?.type === 'location' && location.isWarehouse;

    onChangeEndLocation(location);
    handleChangeTransportCondition(isWarehouse ? 'DOOR' : 'FO', 'end');
  };

  useEffect(() => {
    if (startLocation?.type === 'location') {
      handleChangeTransportCondition('DOOR', 'start');
    } else {
      handleChangeTransportCondition(currentStartCondition, 'start');
    }
  }, [startLocation?.type, currentStartCondition]);

  useEffect(() => {
    if (endLocation?.type === 'location') {
      handleChangeTransportCondition('DOOR', 'end');
    } else {
      handleChangeTransportCondition(currentEndCondition, 'end');
    }
  }, [endLocation?.type]);

  /**
   * Обработчик замены локациями между собой
   */
  const onSwapHandler = () => {
    const toOptions = localStorage.getItem('to-items');
    const fromOptions = localStorage.getItem('from-items');
    const toDefaultOptions = localStorage.getItem('to-default-items');
    const fromDefaultOptions = localStorage.getItem('from-default-items');

    localStorage.setItem('to-items', fromOptions);
    localStorage.setItem('from-items', toOptions);
    localStorage.setItem('to-default-items', fromDefaultOptions);
    localStorage.setItem('from-default-items', toDefaultOptions);
    localStorage.setItem('from-needRefresh', '1');
    localStorage.setItem('to-needRefresh', '1');
    swapEvent$.next(true);
    onSwapLocation();
  };

  return (
    <section className={'range-date'}>
      <div>
        <LocationSelector
          primaryLangId={primaryLangId}
          secondaryLangId={secondaryLangId}
          error={error}
          location={startLocation}
          onChangeLocation={(option) => handleChangeStartLocation(option)}
          label={t('DeliveryForm:Departure')}
          isFrom={true}
        />
        <FormControlLabel
          className="switch"
          defaultChecked
          checked={startTransportingCondition === checkedStartCondition}
          disabled={startLocation?.type !== 'terminal'}
          onChange={(_, checked) =>
            handleChangeTransportCondition(checked ? 'FCA' : 'FOB', 'start', checked)
          }
          control={<MySwitch />}
          label={t('RoutesCalculationWidget:TerminalExpenses')}
        />
      </div>
      {isNotMobile && (
        <div>
          <Tooltip title={t('RoutesCalculationWidget:Locations.SwapLocations')}>
            <MIconButton
              onClick={onSwapHandler}
              disabled={!startLocation || !endLocation}
              className={'replace'}
            >
              <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill={'#9199AA'}
                  d="M4.4 7.6l-.8.8L.1 4.9a.5.5 0 010-.8L3.6.6l.8.8L1.7 4H16v1H1.7l2.7 2.6zM0 11h14.3l-2.7-2.6.8-.8 3.5 3.5c.1.2.1.6 0 .8l-3.5 3.5-.8-.8 2.7-2.6H0v-1z"
                />
              </svg>
            </MIconButton>
          </Tooltip>
        </div>
      )}
      <div>
        <LocationSelector
          primaryLangId={primaryLangId}
          secondaryLangId={secondaryLangId}
          error={error}
          location={endLocation}
          onChangeLocation={(option) => handleChangeEndLocation(option)}
          label={t('DeliveryForm:Destination')}
          direction={'right'}
          isFrom={false}
        />
        <FormControlLabel
          checked={endTransportingCondition === checkedEndCondition}
          defaultChecked
          onChange={(_, checked) =>
            handleChangeTransportCondition(checked ? 'LO' : 'FO', 'end', checked)
          }
          disabled={endLocation?.type !== 'terminal'}
          control={<MySwitch />}
          label={t('RoutesCalculationWidget:UnloadingExpenses')}
        />
      </div>
    </section>
  );
};

// Экспортируем компонент
export default Locations;
