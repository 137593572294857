import { ZodSchema, z } from 'zod';

/**
 * Схема контейнера
 */
const containerParametersSchema = z.object({
  containerId: z.string(),
  containerType: z.string(),
  containerQuantity: z.number(),
  eachContainerWeight: z.number(),
});

const containerParametersIndexSchema = z.object({
  containerParameters: z.array(containerParametersSchema),
  isCustomConditionsOpen: z.boolean(),
  isDangerousCargo: z.boolean(),
  targetCurrencyId: z.string(),
});

/**
 * Схема сборных грузов
 */
const customCargoBaseParametersSchema = z.object({
  quantity: z.number(),
  length: z.string(),
  width: z.string(),
  height: z.string(),
  weight: z.string(),
});

const customCargoPalletParametersSchema = z.object({
  type: z.string(),
  quantity: z.number(),
  normativeHeightOfStacking: z.string(),
  weight: z.number(),
});

const customCargoVolumeParametersSchema = z.object({
  volume: z.string(),
  weight: z.string(),
  height: z.number(),
});

const customCargoParametersSchema = z.object({
  parametersType: z.union([z.literal('base'), z.literal('pallet'), z.literal('volume')]),
  parametersCargoCalcType: z.union([z.literal('base'), z.literal('volume')]),
  baseParameters: customCargoBaseParametersSchema,
  palletParameters: customCargoPalletParametersSchema,
  volumeParameters: customCargoVolumeParametersSchema,
});

const customCargoParametersIndexSchema = z.object({
  customCargoParameters: z.array(customCargoParametersSchema),
  isCustomConditionsOpen: z.boolean(),
  isDangerousCargo: z.boolean(),
  targetCurrencyId: z.string(),
});

/**
 * Схема ветки локаций
 */

const branchItemSchema = z.object({
  id: z.string(),
  parentId: z.string().nullable(),
  type: z.string(),
  isWarehouse: z.boolean().optional(),
  main: z.boolean().optional(),
  visibleName: z.string(),
});

/**
 * Схема типов грузоперевозок
 */
const cargoTypeSchema = z.union([
  z.literal('container'),
  z.literal('custom'),
  z.literal('auto'),
  z.literal('air'),
]);

/**
 * Схема параметров для расчета
 */
export const routeParamsSchemas: Record<string, ZodSchema> = {
  cargoType: cargoTypeSchema.optional(),
  containerParameters: containerParametersIndexSchema.optional(),
  customCargoParameters: customCargoParametersIndexSchema.optional(),
  startLocation: branchItemSchema.optional(),
  endLocation: branchItemSchema.optional(),
  startExpeditionDate: z.date().optional(),
  startTransportingCondition: z.string(),
  endTransportingCondition: z.string(),
  isNeedPrekeridge: z.boolean(),
  isNeedContainerRent: z.boolean(),
  requiredTransportingTypes: z.array(z.number()),
};
