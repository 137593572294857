import Typography from '@mui/material/Typography';
import ServicesSummaryItemStyledComponent from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServicesSummary/components/ServicesSummaryItem/StyledComponent';
import { ProductSummaryProps } from '@services/requests/orderService/types';
import React, { FC } from 'react';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';

// Компонент вывода сводной информации по одной дополнительной услуге
const ServicesSummaryItem: FC<ProductSummaryProps> = ({ product, langId }) => {
  const { useBaseDate } =     useDelivery();
  const [baseData] = useBaseDate().useState();

  const serviceData = JSON.parse(product.data).service;
  const name =
    serviceData.service.localized_names_data?.find((m) => m.lang_id === langId)?.message ||
    serviceData.service.name;
  const { currencies } = baseData;
  const currencyCode = currencies.find(c=>c.id === serviceData.currency_id)?.code || 'RUB';
  return (
    <ServicesSummaryItemStyledComponent>
      <div className={'services-summary-item__list'}>
        <Typography variant={'h4'} className={'services-summary-item__item'}>
          <span>{name}</span>
          <span className={'price'}>
            {product.price.toLocaleString()} {currencyCode}
          </span>
        </Typography>
      </div>
    </ServicesSummaryItemStyledComponent>
  );
};

// Экспортируем компонент
export default ServicesSummaryItem;
