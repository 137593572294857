import TextField from '@mui/material/TextField';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { useState } from 'react';

import FieldCellContainer from '../../../StyledComponents';

const CostField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { useFieldStateServices, actions } = useWidget();
  const { recalculateServices } = actions;
  const [states, setStates] = useFieldStateServices().useState();

  if (row.type === 'result') {
    return <FieldCellContainer>{row.cost}</FieldCellContainer>;
  }
  
  const [constFieldName, costValue] = (Object.entries(states[row.id]) as any[]).find(([key]) =>
    key.includes('paymentValueTemp3')
  );

  const [value, setValue] = useState<string>(costValue.value.toString());

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [row.id]: value,
      }));

      recalculateServices([row.id, ...row.childServiceIdsToUpdate]);
      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => {
      return {
        ...prevState,
        [row.id]: stateUpdater(states[row.id]),
      };
    });

    recalculateServices([row.id, ...row.childServiceIdsToUpdate]);
  };

  const onChangeCost = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (value === '0' || value === '') {
      setValue('0');
      setState((prevState: FieldState) => ({
        ...prevState,
        [constFieldName]: {
          ...prevState[constFieldName],
          value: '0',
        },
      }));
      return;
    }

    setValue(value);
    setState((prevState: FieldState) => {
      return {
        ...prevState,
        [constFieldName]: {
          ...prevState[constFieldName],
          value: value,
        },
      };
    });
  };

  return (
    <TextField
      fullWidth
      style={{ margin: '5px 0' }}
      size={'small'}
      InputLabelProps={{ shrink: true }}
      type={'number'}
      label={''}
      variant="outlined"
      value={value}
      onChange={onChangeCost}
    />
  );
};

export default CostField;
