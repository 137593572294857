import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { useState } from 'react';

const CostField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { useAdditionalInfo, useFieldStateServices, actions } = useWidget();
  const { recalculateService } = actions;
  const [states, setStates] = useFieldStateServices().useState();

  const [paymentValueFieldName, paymentValueValue] = Object.entries(row).find(([key]) =>
    key.includes('paymentvalueTemp5')
  ) as [any, any];
  const [currencyFieldName, currencyValue] = Object.entries(states[row.id]).find(([key]) =>
    key.includes('currencyTemp5')
  ) as [any, any];

  const [value, setValue] = useState<string>(paymentValueValue.value);

  const [currency, setCurrency] = useState<string>(String(currencyValue.handBookValue));
  const [{ currencies }] = useAdditionalInfo().useState([props.row.id]);

  const targetCurrencies = row[currencyFieldName].handbook_limitations;
  const filteredCurrencies = currencies.filter((currency) =>
    targetCurrencies.includes(currency.id)
  );

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [row.id]: value,
      }));

      recalculateService(String(row.id));
      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => {
      return {
        ...prevState,
        [row.id]: stateUpdater(states[row.id]),
      };
    });

    recalculateService(String(row.id));
  };

  const onChangeCost = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (value === '0' || value === '') {
      setValue('0');
      setState((prevState: FieldState) => ({
        ...prevState,
        [row[paymentValueFieldName].code]: {
          ...prevState[row[paymentValueFieldName].code],
          value: '0',
        },
      }));
      return;
    }

    setValue(value);
    setState((prevState: FieldState) => {
      return {
        ...prevState,
        [row[paymentValueFieldName].code]: {
          ...prevState[row[paymentValueFieldName].code],
          value: value,
        },
      };
    });
  };

  const onChangeCurrency = (event: SelectChangeEvent<string>) => {
    setCurrency(event.target.value);
    setState((prevState) => ({
      ...prevState,
      [row[currencyFieldName].code]: {
        ...prevState[row[currencyFieldName].code],
        value: null,
        handBookValue: event.target.value,
      },
    }));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        fullWidth
        size={'small'}
        InputLabelProps={{ shrink: true }}
        type={'number'}
        label={''}
        variant="outlined"
        value={value}
        onChange={onChangeCost}
      />
      <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          disableUnderline
          value={!!filteredCurrencies.length ? currency : ''}
          onChange={onChangeCurrency}
          autoWidth
          label="Валюта"
        >
          {filteredCurrencies.map((currency) => (
            <MenuItem key={'currency-' + currency.id} value={currency.id}>
              {currency.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CostField;
