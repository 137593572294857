import palette from '@assets/theme/palette';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { checkEmptyInputValue, getformattedFloatNumber } from '../../../helpers';
import { CustomCargoCalcType, CustomCargoVolumeParameters } from '../../../types';
import { CustomCargoParametersItemProps } from '../interfaces';

function CustomCargoVolume(props: CustomCargoParametersItemProps) {
  const {
    Position,
    customCargoParameters,
    isDeletable,
    onDelete,
    onChangeCustomCargoParameters,
    onChangeCargoFieldsFilled,
  } = props;
  const { t } = useTranslation('Common');
  const textColor = palette.light.grey['700'];

  const isVolumeInputInvalid = checkEmptyInputValue(customCargoParameters.volumeParameters.volume);
  const isWeightInputInvalid = checkEmptyInputValue(customCargoParameters.volumeParameters.weight);

  useEffect(() => {
	onChangeCargoFieldsFilled([isVolumeInputInvalid, isWeightInputInvalid]);
  }, [isVolumeInputInvalid, isWeightInputInvalid]);

  const handleChangeCustomCargoVolumeParameters = <T extends keyof CustomCargoVolumeParameters>(
    key: T,
    value: CustomCargoVolumeParameters[T]
  ) => {
    onChangeCustomCargoParameters({
      ...customCargoParameters,
      volumeParameters: {
        ...customCargoParameters.volumeParameters,
        [key]: value,
      },
    });
  };

  const handleChangeCustomCargoCalcType = (type: CustomCargoCalcType) => {
    onChangeCustomCargoParameters({
      ...customCargoParameters,
      parametersCargoCalcType: type,
    });
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography variant={'subtitle2'} color={'#707070'} textTransform={'capitalize'}>
        {t('Common:Consignment')} #{Position}
      </Typography>
      <Box className={'cargo-types'}>
        <Typography
          className={'cargo-types__title'}
          component={'span'}
          variant={'body2'}
          sx={{ color: textColor, fontSize: '12px' }}
        >
          {t('Common:CalculationType')}
        </Typography>
        <Button
          size={'small'}
          variant={'outlined'}
          className={'not-selected'}
          color={'inherit'}
          onClick={() => handleChangeCustomCargoCalcType('base')}
        >
          {t('Common:CargoPlace')}
        </Button>
        <Button size={'small'} variant={'outlined'}>
          {t('Common:Consignment')}
        </Button>
      </Box>
      <Box className={'cargo-inputs-block'}>
        <TextField
		  error={isVolumeInputInvalid}
		  focused={isVolumeInputInvalid}
          fullWidth
          variant={'standard'}
          value={customCargoParameters.volumeParameters.volume}
          onChange={(event) =>
            handleChangeCustomCargoVolumeParameters('volume', getformattedFloatNumber(event.target.value))
          }
          type={'text'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:VolumeM3')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
		  error={isWeightInputInvalid}
		  focused={isWeightInputInvalid}
          fullWidth
          variant={'standard'}
          value={customCargoParameters.volumeParameters.weight}
          onChange={(event) =>
            handleChangeCustomCargoVolumeParameters('weight', getformattedFloatNumber(event.target.value))
          }
          type={'text'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:ConsignmentKg')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {isDeletable && (
        <IconButton
          sx={{
            position: 'absolute',
            right: '-15px',
            top: '-15px',
          }}
          size={'small'}
          onClick={onDelete}
        >
          <CloseIcon sx={{ fontSize: 22 }} />
        </IconButton>
      )}
    </Box>
  );
}

export default CustomCargoVolume;
