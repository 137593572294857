import useBaseDataContext from '@context/useBaseDataContext';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import useBasket from '@pages/Basket/useBasket';
import { useYaMetrik } from '@context/useYaMetrik';
import { useBasketCounter } from './Basket/hooks/useBasketCounter';
import React, { useEffect, useState } from 'react';

const InitWidget = () => {
  const [isInit, setIsInit] = useState(false);

  const [settings] = useMyRouting().useSettings().useState();
  const initBaseData = useBaseDataContext().actions.init;
  const initBasket = useBasket().actions.init;
  const initDelivery = useDelivery().actions.init;
  const [page] = useMyRouting().usePage().useState();
  const initYaCounterId = useYaMetrik().actions.init;
  const initLocalization = useMyRouting().actions.init;

  useBasketCounter();

  const isDisableRoutesCalculation = page === "basket";

  const isSettingsNotInited = Object.values(settings).some( setting => !setting );

  useEffect(() => {
	initYaCounterId();
	initLocalization();
  }, []);

  useEffect(() => {
    if (!isInit && !isSettingsNotInited) {
      setIsInit(true);
      initBasket(settings.primaryLanguageId, settings.currencyId).then(() => {
        initDelivery(
          settings.primaryLanguageId,
          settings.secondaryLanguageId,
          settings.currencyId,
		  isDisableRoutesCalculation
        ).then(() =>
          initBaseData(
            settings.primaryLanguageId,
            settings.secondaryLanguageId,
            settings.currencyId
          )
        );
      });
    }
  }, [settings, isSettingsNotInited]);

  return <></>;
};

export default InitWidget;
