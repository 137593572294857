import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { ServicesServicePriceCalculationServiceName } from '@services/requests/servicesService/servicesServicesService/interface';

export interface ServicesBySectionType {
  sectionName: ServicesServicePriceCalculationServiceName;
  serviceParent: ServicesServicePriceCalculationServiceName;
  services: OrderProduct[];
}

const getServicesBySection = (services: OrderProduct[]): ServicesBySectionType[] => {
  const servicesBySection = services.reduce((acc: { [key: string]: ServicesBySectionType }, s) => {
    const data = JSON.parse(s.data);

    let { sectionName, serviceParent } = data;

    if (Array.isArray(data) && !sectionName) {
      sectionName = JSON.parse(data[0].data).sectionName;
      serviceParent = JSON.parse(data[0].data).serviceParent;
    }

    if (!acc[sectionName.id]) {
      acc[sectionName.id] = {
        sectionName,
        serviceParent,
        services: [s],
      };
      return acc;
    }
    acc[sectionName.id].services.push(s);
    return acc;
  }, {});
  return Object.values(servicesBySection);
};

export default getServicesBySection;
