import palette from '@assets/theme/palette';
import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import SearchIcon from '@components/icons/SearchIcon';
import { YaTargetWrapper } from '@components/YaTargetWrapper/YaTargetWrapper';
import BitrixUserContext from '@context/useBitrixUser';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip'; // Компонент виджета расчета маршрутов
import ContainerParametersComponent from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/containerCargo/ContainerParametersComponent';
import CustomCargoParametersComponent from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/customCargo/CustomCargoParametersComponent';
import { CargoType } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/interfaces';
import VideoInstruction from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/VideoInstruction';
import { useStateSubscriber } from '@settings/core/stateContexts/useContextSubscriber';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BehaviorSubject } from 'rxjs';
import { onCopyToClipboard } from 'src/utils/browser-permission-checks';

import useDelivery from '../../useDelivery';
import CalculationLimit from './CalculationLimit';
import CargoDate from './CargoDate';
import CargoTypeSelect from './CargoTypeSelect';
import Locations from './Locations';
import RouteParameters from './RouteParameters';
import { ShareMenu } from './ShareMenu';
import RoutesCalculationWidgetStyledContainer from './StyledComponents';

interface RoutesCalculationWidgetProps {
  handleShowFormButton: () => void;
  openDrawer: boolean;
}

export const $isCalculationLimitExceeded = new BehaviorSubject<boolean>(false);

// Компонент виджета расчета маршрутов
const RoutesCalculationWidget: FC<RoutesCalculationWidgetProps> = ({ handleShowFormButton }) => {
  const { user } = useContext(BitrixUserContext);
  const [isLimitExceeded] = useStateSubscriber<boolean>($isCalculationLimitExceeded).useState();

  const {
    useBaseDate,
    useRouteParams,
    useValidation,
    useSettings,
    useIsLoading,
    useIsNeedReloading,
    useIsArchiveLoading,
    actions,
  } = useDelivery();
  const theme = useTheme();
  const setArchiveMode = useIsArchiveLoading().setState;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation([
    'RoutesCalculationWidget',
    'Actions',
    'LocationSelector',
    'Common',
  ]);
  const [baseData] = useBaseDate().useState();
  const [error] = useValidation().useState();
  const [routeParams, setRouteParameter] = useRouteParams().useState();
  const [settings] = useSettings().useState();
  const [isLoading] = useIsLoading().useState();
  const [isNeedRoutesReloading] = useIsNeedReloading().useState(false);

  const [isCargoFieldsFilled, setIsCargoFieldsFilled] = useState(true);
  const [copyNotificationVisible, setCopyNotificationVisible] = useState(false);

  const { recalculateRoutes, resetWidget, saveToSearchParams, resetSearchParams } = actions;
  const { startExpeditionDate, startLocation, endLocation } = routeParams;

  const startTransportingCondition = baseData?.startTransportingConditions.find(
    (c) => c.id === routeParams.startTransportingCondition
  );

  const isContainer = useMemo(
    () => routeParams.cargoType && ['container', 'auto'].indexOf(routeParams.cargoType) !== -1,
    [routeParams.cargoType]
  );

  const maybeCalculated = useMemo(
    () => !!startExpeditionDate && !!startLocation && !!endLocation && !!routeParams.cargoType,
    [startExpeditionDate, startLocation, endLocation, routeParams.cargoType]
  );

  const isStartTerminalNotSelected = !!startLocation && startLocation.type !== 'terminal';
  const isStartWarehouseNotSelected =
    !!startLocation && startLocation.type === 'location' && !startLocation.isWarehouse;

  const isEndTerminalNotSelected = !!endLocation && endLocation.type !== 'terminal';
  const isEndWarehouseNotSelected =
    !!endLocation && endLocation.type === 'location' && !endLocation.isWarehouse;

  const isStartNotSelected =
    !startLocation || (isStartTerminalNotSelected && isStartWarehouseNotSelected);
  const isEndNotSelected = !endLocation || (isEndTerminalNotSelected && isEndWarehouseNotSelected);

  useEffect(() => {
    const isFormInvalid =
      !routeParams.cargoType || isStartNotSelected || isEndNotSelected || !isCargoFieldsFilled;

    if (!isFormInvalid && user) {
      saveToSearchParams();
    } else {
      resetSearchParams();
    }
  });

  const handleDeleteContainerParameters = (index: number) => {
    setRouteParameter((current) => ({
      ...current,
      containerParameters: {
        ...current.containerParameters!,
        containerParameters: current.containerParameters!.containerParameters.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };
  const handleDeleteCustomParameters = (index: number) => {
    setRouteParameter((current) => ({
      ...current,
      customCargoParameters: {
        ...current.customCargoParameters!,
        customCargoParameters: current.customCargoParameters!.customCargoParameters.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const handleSearchButton = () => {
    setArchiveMode(false);
    recalculateRoutes(true);

    if (isMobile) {
      handleShowFormButton();
    }
  };

  const onChangeCargoType = (cargoType: CargoType) => {
    let requiredTransportingTypes = [];
    switch (cargoType) {
      case 'air':
        requiredTransportingTypes = [3];
        break;
      case 'auto':
        requiredTransportingTypes = [4];
        break;
      default:
        requiredTransportingTypes = [];
        break;
    }

    setRouteParameter((current) => ({
      ...current,
      cargoType,
      requiredTransportingTypes,
    }));
  };

  const changeCargoFieldsFilledHandler = (validityResults: boolean[]) => {
    const isFieldsValid = validityResults.every((value) => !value);

    setIsCargoFieldsFilled(isFieldsValid);
  };

  const copyCalculationLinkHandler = () => {
    onCopyToClipboard(window.location.href, () => {
      setCopyNotificationVisible(true);
      setTimeout(() => {
        setCopyNotificationVisible(false);
      }, 2000);
    });
  };

  return (
    <>
      <RoutesCalculationWidgetStyledContainer>
        <div className={'form-wrapper'}>
          <div className={'form-wrapper__fields'}>
            <Locations
              BaseData={baseData}
              error={error}
              startTransportingCondition={routeParams.startTransportingCondition}
              endTransportingCondition={routeParams.endTransportingCondition}
              startLocation={routeParams.startLocation}
              endLocation={routeParams.endLocation}
              onSwapLocation={() => {
                setRouteParameter((current) => ({
                  ...current,
                  startLocation: current.endLocation,
                  endLocation: current.startLocation,
                  startTransportingCondition: current.endTransportingCondition,
                  endTransportingCondition: current.startTransportingCondition,
                }));
              }}
              onChangeEndLocation={(endLocation) => {
                setRouteParameter((current) => ({
                  ...current,
                  endLocation,
                }));
              }}
              onChangeStartLocation={(startLocation) => {
                setRouteParameter((current) => ({
                  ...current,
                  startLocation,
                }));
              }}
              onChangeEndTransportingCondition={(endTransportingCondition, checked) => {
                setRouteParameter((current) => ({
                  ...current,
                  endTransportingCondition,
                  isEndLoadingIncluded: checked,
                }));
              }}
              onChangeStartTransportingCondition={(startTransportingCondition, checked) => {
                setRouteParameter((current) => ({
                  ...current,
                  startTransportingCondition,
                  isStartLoadingIncluded: checked,
                }));
              }}
              secondaryLangId={settings.secondaryLanguageId}
              primaryLangId={settings.primaryLanguageId}
            />
            <CargoDate
              currentDate={routeParams.startExpeditionDate}
              onChangeDate={(startExpeditionDate) => {
                setRouteParameter((current) => ({
                  ...current,
                  startExpeditionDate,
                }));
              }}
            />
            <CargoTypeSelect
              cargoType={routeParams.cargoType}
              onChangeCargoType={onChangeCargoType}
            />
          </div>
          {!isMobile && (
            <YaTargetWrapper target="calculator_poisk">
              <Fab
                color={'primary'}
                className={'form-wrapper__search'}
                onClick={handleSearchButton}
                size={'medium'}
                disabled={
                  !routeParams.cargoType ||
                  isStartNotSelected ||
                  isEndNotSelected ||
                  !isCargoFieldsFilled
                }
              >
                <SearchIcon
                  sx={{
                    fontSize: 25,
                    color: 'common.white',
                  }}
                />
              </Fab>
            </YaTargetWrapper>
          )}
          {!isMobile && (
            <>
              {!error && <div className={'error-massage'}>Пожалуйста, заполните все поля</div>}
              {!routeParams.cargoType && (
                <Box className={'video-instruction-block'}>
                  <VideoInstruction />
                </Box>
              )}
            </>
          )}
        </div>
        {!!routeParams.cargoType && (
          <>
            {isContainer && !!routeParams.containerParameters && (
              <ContainerParametersComponent
                BaseData={baseData}
                containerParameters={routeParams.containerParameters}
                mainLanguage={settings.secondaryLanguageId}
                systemLanguage={settings.primaryLanguageId}
                copyNotificationVisible={copyNotificationVisible}
                handleDeleteContainerParameters={handleDeleteContainerParameters}
                onChangeCargoFieldsFilled={changeCargoFieldsFilledHandler}
                baseConditionsSlot={
                  <RouteParameters
                    isNeedPrekeridge={routeParams.isNeedPrekeridge}
                    isNeedContainerRent={routeParams.isNeedContainerRent}
                    isContainerRentDisabled={!isContainer}
                    isPrekeridgeDisabled={!startTransportingCondition.is_prekeridge_available}
                    onChangeIsNeedContainerRent={(isNeedContainerRent) => {
                      setRouteParameter((current) => ({
                        ...current,
                        isNeedContainerRent,
                      }));
                    }}
                    onChangeIsNeedPrekeridge={(isNeedPrekeridge) => {
                      setRouteParameter((current) => ({
                        ...current,
                        isNeedPrekeridge,
                      }));
                    }}
                  />
                }
                resetSlot={
                  !isMobile && (
                    <>
                      <BitrixDisableUserGuard>
                        <Tooltip title={t('RoutesCalculationWidget:CopyCalculationLink')}>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={copyCalculationLinkHandler}
                          >
                            <LinkIcon
                              sx={{ transform: 'rotate(-45deg)' }}
                              style={{ color: palette.light.grey[700] }}
                            />
                          </IconButton>
                        </Tooltip>
                      </BitrixDisableUserGuard>
                      <ShareMenu />
                      <Tooltip title={t('RoutesCalculationWidget:ResetCalculationOptions')}>
                        <IconButton color="primary" size="small" onClick={resetWidget}>
                          <CloseIcon style={{ color: palette.light.error.main }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                }
              />
            )}
            {!isContainer && !!routeParams.customCargoParameters && (
              <CustomCargoParametersComponent
                BaseData={baseData}
                customCargoParameters={routeParams.customCargoParameters}
                mainLanguage={settings.secondaryLanguageId}
                systemLanguage={settings.primaryLanguageId}
                copyNotificationVisible={copyNotificationVisible}
                handleDeleteCustomParameters={handleDeleteCustomParameters}
                onChangeCargoFieldsFilled={changeCargoFieldsFilledHandler}
                baseConditionsSlot={
                  <RouteParameters
                    isNeedPrekeridge={routeParams.isNeedPrekeridge}
                    isNeedContainerRent={routeParams.isNeedContainerRent}
                    isContainerRentDisabled={!isContainer}
                    isPrekeridgeDisabled={!startTransportingCondition.is_prekeridge_available}
                    onChangeIsNeedContainerRent={(isNeedContainerRent) => {
                      setRouteParameter((current) => ({
                        ...current,
                        isNeedContainerRent,
                      }));
                    }}
                    onChangeIsNeedPrekeridge={(isNeedPrekeridge) => {
                      setRouteParameter((current) => ({
                        ...current,
                        isNeedPrekeridge,
                      }));
                    }}
                  />
                }
                resetSlot={
                  !isMobile && (
                    <>
                      <BitrixDisableUserGuard>
                        <Tooltip title={t('RoutesCalculationWidget:CopyCalculationLink')}>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={copyCalculationLinkHandler}
                          >
                            <LinkIcon
                              sx={{ transform: 'rotate(-45deg)' }}
                              style={{ color: palette.light.grey[700] }}
                            />
                          </IconButton>
                        </Tooltip>
                      </BitrixDisableUserGuard>
                      <ShareMenu />
                      <Tooltip title={t('RoutesCalculationWidget:ResetCalculationOptions')}>
                        <IconButton color="primary" size="small" onClick={resetWidget}>
                          <CloseIcon style={{ color: palette.light.error.main }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                }
              />
            )}
          </>
        )}
        {isMobile && (
          <Box
            sx={{
              mb: 1,
            }}
          >
            <div className={'wrapper-actions-form'}>
              <Button
                disabled={isLoading || isNeedRoutesReloading || !maybeCalculated}
                variant={'contained'}
                fullWidth
                size={'large'}
                onClick={handleSearchButton}
                startIcon={<SearchIcon sx={{ color: 'white' }} />}
                className={'search-button'}
              >
                {t('Actions:Search')}
              </Button>
              <Tooltip title={t('RoutesCalculationWidget:ResetCalculationOptions')}>
                <IconButton color="primary" size="small" onClick={resetWidget}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className="share-wrapper">
              <BitrixDisableUserGuard>
                <Tooltip title={t('RoutesCalculationWidget:CopyCalculationLink')}>
                  <IconButton color="primary" size="small" onClick={copyCalculationLinkHandler}>
                    <LinkIcon
                      sx={{ transform: 'rotate(-45deg)' }}
                      style={{ color: palette.light.grey[700] }}
                    />
                  </IconButton>
                </Tooltip>
              </BitrixDisableUserGuard>
              <ShareMenu />
            </div>
            {!error && <div className={'error-massage'}>Пожалуйста, заполните все поля</div>}
            <VideoInstruction />
          </Box>
        )}
      </RoutesCalculationWidgetStyledContainer>
      <CalculationLimit isVisible={isLimitExceeded} />
    </>
  );
};

// Экспортируем компонент
export default RoutesCalculationWidget;
