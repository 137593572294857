import { getStateFields, loadContractors } from '@pages/AdditionalServices/context/helpers';
import { useCalculateServices } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/useCalculateService';
import { getAddServiceItems, getDirItems } from '@pages/MainWidget/helpers';
import { currencyLoader } from '@services/requests/searchLoaders/currencyLoader';
import { ServicesDirProps } from '@services/requests/servicesService/servicesDirectoriesService/interface';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';
import { taxLoader } from '@services/requests/taxLoader';
import { createState, saveToStore } from '@settings/core/stateContexts/useContextSubscriber';

import ADDITIONAL_INFO from './mock';
import {
  AdditionalInfo,
  BasketAdditionalService,
  FieldState,
  FieldStates,
  FilterListingProps,
  LevelInner,
  SelectedToBasketAdditionalService,
  ServicesCount,
  TabsItemProps,
} from './types';

/** Контекст флага инизиализации формы. **/
const [useInit, $init, resetInit] = createState<boolean>(false);
/** Контекст флага инизиализации формы. **/
const [useLastDirectory, $lastDirectory] = createState<ServicesDirProps>(null, 'LAST_DIRECTORY');
/** Контекст запуска поиска доп услуг формы. **/
const [useProcessLoadingDataForConstructor, $processLoadingDataForConstructor] =
  createState<boolean>(false, 'READY_TO_SEARCH');
/** Контекст определения готовности сбора листинга доп услуг.  **/
const [useReadyConstructor, $isReadyConstructor, resetIsReadyConstructor] = createState<boolean>(
  false,
  'READY_TO_DISPLAY'
);
/** Контекст флага для определения возможности отображения листингов.  **/
const [useEndSearch] = createState<boolean>(false, 'END_SEARCH');
/** Контекст с дополнительными данными листинга доп услуг.  **/
const [useAdditionalInfo, $additionalInfo] = createState(ADDITIONAL_INFO, 'ADDITIONAL_INFO');
const [useServicesCount, _$countServices, resetCountServices] = createState<ServicesCount>(
  {},
  'SERVICES_COUNT'
);
const [useServicesCost, _$costServices] = createState<ServicesCount>({}, 'SERVICES_COST');
/** Контекст активного элемента верхнего уровня директорий доп услуг + блок доставка.  **/
const [useTabsActive, $activeTabs] = createState<number>(0, 'ACTIVE_TAB');
/** Контекст табов верхнего уровня директорий доп услуг + блок доставка.  **/
const [useTabs, $tabs] = createState<TabsItemProps[]>([], 'FORM_TABS');
/** Контекст сбора древа директорий основной формы доп услуг.  **/
const [useAdditionalFormTrees, $additionalFormTrees, resetAdditionalFormTrees] = createState<
  LevelInner[]
>([], 'TREES_TABS');
/**  Контекст для хранения загруженных доп услуг.  **/
const [useAdditionalServices, $additionalServices, resetAdditionalServices] = createState<
  ServicesProps[]
>([], 'ADDITIONAL_SERVICES');
/** Контекст значений полей основной формы.  **/
const [useFieldStateMainForm, $fieldStateMainForm, resetFieldStateMainForm] =
  createState<FieldState>({}, 'STATE_MAIN_FORM');
/** Контекст значений полей всех доп услуг.  **/
const [useFieldStateServices, $fieldStateServices, resetFieldStateServices] =
  createState<FieldStates>({}, 'FIELD_STATES');
/** Контекст всех расчетов для сбора заказа.  **/
const [useBasketAdditionalService, $basketAdditionalService, resetBasketData] =
  createState<BasketAdditionalService>({}, 'BASKET_DATA');
/** Контекст выбранных для заказа доп услуг.  **/
const [useSelectedSubAddServices, _, resetSelectedItemsToBasket] =
  createState<SelectedToBasketAdditionalService>({}, 'SELECTED_ADDITIONAL_SERVICES');
/** Контекст фильтра листинга доп услуг.  **/
const [useListingFilter, _$listingFilter, resetFilter] = createState<FilterListingProps>(
  {},
  'ADDITIONAL_FILTER'
);
/** Контекст фильтра по умолчанию листинга доп услуг.  **/
const [useDefaultListingFilter, _$defaultListingFilter, resetDefaultFilter] =
  createState<FilterListingProps>({}, 'DEFAULT_ADDITIONAL_FILTER');
/** Контекст для хранения загруженных вариантов доп услуг.  **/
const [useVariants, $variants, resetVariants] = createState<ServicesDirProps[]>([], 'VARIANTS');
/** Контекст для хранения выбранного варианта.  **/
const [useActiveVariant, $activeVariant, resetActiveVariant] = createState<ServicesDirProps>(
  null,
  'ACTIVE_VARIANT'
);

/** Метод расчета сервисов и сохранения данных в корзину  **/
const calculateServices = (r: ServicesProps[]) => {
  const fieldStateMainForm = $fieldStateMainForm.getValue();

  $additionalServices.next(r);
  const allServices = r.reduce((acc: ServicesProps[], service: ServicesProps) => {
    acc.push(...[service, ...service.childs]);

    return acc;
  }, []);

  const servicesStates = allServices.reduce((acc, service) => {
    acc = {
      ...acc,
      [service.id]: getStateFields(service.allFields, fieldStateMainForm),
    };

    return acc;
  }, {});

  $fieldStateServices.next(servicesStates);
  $isReadyConstructor.next(true);
};

/** Очищение данных при смене табуляции корня дополнительных услуг. **/
const clearData = () => {
  resetInit();
  resetFilter('clearWithLocalStore');
  resetDefaultFilter('clearWithLocalStore');
  resetIsReadyConstructor();
  resetBasketData();
  resetSelectedItemsToBasket();
  resetAdditionalFormTrees();
  resetFieldStateMainForm();
  resetFieldStateServices();
  resetVariants();
  resetActiveVariant();
  resetAdditionalServices();
};

/** Очищение данных при смене табуляции корня дополнительных услуг. **/
const clearDataForDelivery = () => {
  resetInit();
  resetCountServices('clearWithLocalStore');
  resetFilter('clearWithLocalStore');
  resetDefaultFilter('clearWithLocalStore');
  resetIsReadyConstructor();
  resetBasketData();
  resetSelectedItemsToBasket();
  resetFieldStateMainForm();
  resetFieldStateServices();
  resetVariants();
  resetActiveVariant();
  resetAdditionalServices();
};

const clearSwapData = () => {
  resetCountServices('clearWithLocalStore');
  resetBasketData('clearWithLocalStore');
  resetSelectedItemsToBasket('clearWithLocalStore');
  resetFieldStateServices('clearWithLocalStore');
  resetAdditionalServices('clearWithLocalStore');
};

/**  Загрузка первоначальных данных на момент запуска поиска формы.  **/
const loadDataForListing = () => {
  resetIsReadyConstructor();
  resetCountServices('clearWithLocalStore');
  resetFilter('clearWithLocalStore');
  resetDefaultFilter('clearWithLocalStore');
  resetSelectedItemsToBasket();
  resetFieldStateServices();
  resetVariants();
  resetActiveVariant();
  resetAdditionalServices();

  $init.next(true);
  const tabsTrees = $additionalFormTrees.getValue();
  const activeTab = $activeTabs.getValue();
  const lastItem = [...tabsTrees].reverse()[0];
  const currentItem = lastItem?.options.find((o) => o.value === lastItem.entityId);

  if (activeTab === 0) {
    return;
  }
  if (!currentItem) {
    const item = $activeTabs.getValue();
    const tabs = $tabs.getValue();
    const firstItem = tabs.find((i, index) => index + 1 === item);
    $lastDirectory.next({
      ...firstItem,
      localized_names: [],
    });
    getAddServiceItems(firstItem.id).then(calculateServices);
    return;
  }

  if (currentItem.entity.hasOwnProperty('is_variants') && currentItem.entity.is_variants) {
    getDirItems(currentItem.entity.childs_id).then((r) => {
      $variants.next(r);
      $activeVariant.next(r[0]);
      $lastDirectory.next(r[0]);
    });
    return;
  }

  if (lastItem.entityId) {
    const currentDir = lastItem.options.find((i) => i.entity.id === lastItem.entityId).entity;
    getAddServiceItems(lastItem.entityId).then(calculateServices);
    $lastDirectory.next(currentDir);
  }
};

/**  Загрузка данных при выборе варианта. **/
const loadAdditionalServices = (currentItem: ServicesDirProps) => {
  clearSwapData();
  if (currentItem) {
    getAddServiceItems(currentItem.id).then(calculateServices);
  }
};

/** Метод проверки валидности полей формы для запроса. **/
const checkFormState = (tabsTrees: LevelInner[]) => {
  const state = $fieldStateMainForm.getValue();
  const tabs = $tabs.getValue();
  const active = $activeTabs.getValue();
  const currentService = tabs.find((i, index) => index + 1 === active);

  if (!currentService) {
    clearDataForDelivery();
    return;
  }

  const allFields = tabsTrees.reduce(
    (acc, i) => {
      const fields =
        i.options
          .find((e) => e.entity.id === i.entityId)
          ?.entity.fields.filter((f) => f.type !== 'hidden') || [];

      acc = [...acc, ...fields];

      return acc;
    },
    [...currentService.fields.filter((f) => f.type !== 'hidden')]
  );

  const filteredPrevState = Object.keys(state).reduce((acc, key) => {
    if (allFields.map((i) => i.code).includes(key)) {
      acc = {
        ...acc,
        [key]: state[key],
      };
    }

    return acc;
  }, {});

  $fieldStateMainForm.next(
    allFields.reduce((acc, field) => {
      const cashField = filteredPrevState[field.code];
      if (cashField) {
        acc = {
          ...acc,
          [field.code]: cashField,
        };
      } else {
        if (field.handbook_limitations.length === 1) {
          acc = {
            ...acc,
            [field.code]: {
              handBookValue: field.handbook_limitations[0],
              value: null,
            },
          };
        }

        if (field.value || field.value === 0) {
          acc = {
            ...acc,
            [field.code]: {
              handBookValue: null,
              value: Number(field.value),
            },
          };
        }
      }

      return acc;
    }, {})
  );
};

/** Метод расчета доп услуги. **/
const recalculateService = (id: string) => {
  const allServices = $additionalServices
    .getValue()
    .map((service) => [service, ...service.childs.map((c) => c)])
    .flat();
  const [service] = allServices.filter((i) => String(i.id) === String(id));
  const servicesStates = $fieldStateServices.getValue();
  const params = [
    {
      currency_id: 1,
      service_id: Number(id),
      values: Object.keys(servicesStates[id]).map((key) => {
        const childState = servicesStates[id][key];
        const value =
          childState.value !== null ? String(childState.value) : String(childState.handBookValue);
        return {
          key,
          value,
        };
      }),
    },
  ];

  const [paramsForCheck] = params;

  if (paramsForCheck.values.length !== service.allFields.length) {
    return;
  }

  useCalculateServices(params).then((data) => {
    const basket = $basketAdditionalService.getValue();
    const defaultBasket = data.reduce((acc, i) => {
      acc = {
        ...acc,
        [i.serviceId]: {
          ...i,
          service,
        },
      };

      return acc;
    }, basket);

    $basketAdditionalService.next(defaultBasket);
    saveToStore('BASKET_DATA', defaultBasket);
  });
};

/** Метод расчета доп услуг. **/
const recalculateServices = (ids: string[]) => {
  const parentServiceId = ids[0];
  const allServices = $additionalServices
    .getValue()
    .map((service) => [service, ...service.childs.map((c) => c)])
    .flat();
  const servicesStates = $fieldStateServices.getValue();
  const params = ids.map((id) => ({
    currency_id: 1,
    service_id: Number(id),
    values: Object.keys(servicesStates[id]).map((key) => {
      const childState = servicesStates[id][key];
      const parentValue = servicesStates[parentServiceId][key]?.value;
      const value =
        childState.value !== null ? String(childState.value) : String(childState.handBookValue);
      return {
        key,
        value: parentValue ? String(servicesStates[parentServiceId][key]?.value) : value,
      };
    }),
  }));

  useCalculateServices(params).then((data) => {
    const basket = $basketAdditionalService.getValue();

    const defaultBasket = data.reduce((acc, i) => {
      const [service] = allServices.filter((el) => String(el.id) === String(i.serviceId));

      acc = {
        ...acc,
        [i.serviceId]: {
          ...i,
          service,
        },
      };

      return acc;
    }, basket);

    $basketAdditionalService.next(defaultBasket);
    saveToStore('BASKET_DATA', defaultBasket);
  });
};

/** Метод загрузки доп данных. **/
const init = async () => {
  const [contractors, currencies, taxes] = await Promise.all([
    loadContractors(),
    currencyLoader().Load(),
    taxLoader().Load(),
  ]);

  $additionalInfo.next(<AdditionalInfo>{
    taxes,
    currencies,
    contractors,
  });
  $init.next(true);
};

const actions = {
  recalculateServices,
  recalculateService,
  init,
};

// Контроллер проверки полей основной формы.
$additionalFormTrees.subscribe(checkFormState);
// Контроллер запуска загрузки данных для листинга.
$processLoadingDataForConstructor.subscribe(loadDataForListing);
// Контроллер очистки сводных данных при изменении корневой директории доп услуг.
$activeTabs.subscribe(clearData);
// Контроллер очистки сводных данных при изменении варианта доп услуг.
$activeVariant.subscribe(loadAdditionalServices);

const useWidget = () => {
  return {
    useSelectedSubAddServices,
    useVariants,
    useActiveVariant,
    useListingFilter,
    useLastDirectory,
    useDefaultListingFilter,
    useServicesCount,
    useServicesCost,
    useReadyConstructor,
    useProcessLoadingDataForConstructor,
    useFieldStateServices,
    useBasketAdditionalService,
    useAdditionalServices,
    useAdditionalInfo,
    useInit,
    useFieldStateMainForm,
    useTabsActive,
    useEndSearch,
    useAdditionalFormTrees,
    useTabs,
    actions,
  };
};

export default useWidget;
