import MButton from '@assets/mui/@material-extend/MButton';
import { useMediaQuery } from '@material-ui/core';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import PrintIcon from '@mui/icons-material/Print';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useWidget from '@pages/AdditionalServices/context';
import { ContractorsOptions, FieldState } from '@pages/AdditionalServices/context/types';
import PropertyItem from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceListing/components/SimpleAdditionalServiceListing/components/PropertyItem';
import FieldsMap from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/FieldsMap';
import useBasket from '@pages/Basket/useBasket';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';
import { TaxData } from '@services/requests/taxLoader/TaxLoaderQuery';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import Container, { PriceWithTaxItemContainer } from './StyledComponents';

interface Props {
  service: ServicesProps;
  contractorsOptions: ContractorsOptions;
  taxes: TaxData[];
  currencies: CurrencyData[];
}

const defaultResult = {
  calculationAccordingToApplication: true,
  basePrice: 1,
  taxInTargetCurrency: 1,
  base_tax: 1,
  converted_price: { amount: 1, conversion_fee: 1 },
  converted_price_with_tax: { amount: 1, conversion_fee: 1 },
  converted_tax: { amount: 1, conversion_fee: 1 },
  course: 1,
};

interface TextItemProps {
  value?: number;
  currency?: string;
  title?: string;
}

interface PriceWithTaxItemProps {
  value: number;
  taxValue: number;
  currency: string;
}

const PriceWithTaxItem = ({ value, taxValue, currency }: PriceWithTaxItemProps) => {
  return (
    <PriceWithTaxItemContainer>
      <Typography variant={'subtitle1'}>
        {value} {currency}
      </Typography>
      <Typography variant={'caption'} color={'#707070'} fontWeight={'bold'}>
        в т.ч. налог {taxValue} {currency}
      </Typography>
    </PriceWithTaxItemContainer>
  );
};

const TextItem = ({ value, title, currency }: TextItemProps) => {
  if (value === 0) {
    return <></>;
  }

  return (
    <div className={'item'}>
      <Typography variant={'subtitle1'}>{title}</Typography>
      <Typography variant={'subtitle1'}>
        {value} {currency}
      </Typography>
    </div>
  );
};

const ValueEmpty = ({ title }: TextItemProps) => {
  return (
    <PriceWithTaxItemContainer>
      <Typography variant={'subtitle1'}>{title}</Typography>
    </PriceWithTaxItemContainer>
  );
};

/**
 * Компонент вывода карточки маршрута
 */
const SimpleAdditionalServiceItem = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const theme = useTheme();

  const { useTabs, useTabsActive } = useWidget();
  const [tabs] = useTabs().useState();
  const [activeTabs] = useTabsActive().useState();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { service, taxes, contractorsOptions, currencies } = props;
  const { name, description, id } = service;
  const { actions, useFieldStateServices, useBasketAdditionalService, useSelectedSubAddServices } =
    useWidget();

  const [states, setStates] = useFieldStateServices().useState([id]);
  const [selected, setSelected] = useSelectedSubAddServices().useState();
  const [basket] = useBasketAdditionalService().useState();
  const { recalculateService } = actions;

  const {
    useOrder,
    actions: { addToCartSingleAdditionalService, deleteFromCart },
  } = useBasket();

  const [order] = useOrder().useState();

  const isNeedToCalculate = service.fields.filter((f) => f.type !== 'hidden').length === 0;
  const state = states[id];
  const inCard = selected[id];
  const inBasket = basket[id];

  useEffect(() => {
    isNeedToCalculate && recalculateService(String(id));
  }, []);

  const onClick = () => {
    const childrenIds = service.childs.map((i) => i.id);

    recalculateService(String(id));

    setSelected((prev) => {
      const checked = prev[id] || false;
      if (!checked) {
        return {
          ...prev,
          [id]: service,
        };
      }

      return {
        ...Object.keys(prev).reduce((acc, k) => {
          if (k !== id && !childrenIds.includes(k)) {
            acc = {
              ...acc,
              [k]: prev[k],
            };
          }
          return acc;
        }, {}),
      };
    });
  };

  const onChange = () => {
    setSelected((prev) => {
      const checked = prev[id] || false;
      if (!checked) {
        return {
          ...prev,
          [id]: service,
        };
      }

      return {
        ...Object.keys(prev).reduce((acc, k) => {
          if (k !== id) {
            acc = {
              ...acc,
              [k]: prev[k],
            };
          }
          return acc;
        }, {}),
      };
    });

    const product = order.products.find(
      (p) => JSON.parse(p.data)?.service?.service_id === inBasket?.serviceId
    );
    if (product) {
      deleteFromCart(product);
      return;
    }
    const { name, localized_names, id: parentId } = tabs[activeTabs - 1];
    addToCartSingleAdditionalService(inBasket, { id: parentId, name, localized_names });
  };

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [id]: value,
      }));
      if (!isNeedToCalculate && !!inBasket) {
        recalculateService(String(id));
      }

      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => ({
      ...prevState,
      [id]: stateUpdater(states[id]),
    }));

    if (!isNeedToCalculate && !!inBasket) {
      recalculateService(String(id));
    }
  };

  const {
    calculationAccordingToApplication = false,
    basePrice,
    taxInTargetCurrency,
  } = inBasket?.result?.result || defaultResult;

  const properties = service.allProperties.reduce((acc, item) => {
    const currentValue = service.property_values.find(
      (i) => Number(i.property_id) === Number(item.id)
    );
    if (currentValue) {
      const oneItem = {
        ordinal: item.ordinal,
        value: currentValue.value,
        label: item.name,
        property: item,
      };

      acc.push(oneItem);
    }

    return acc;
  }, []);

  return (
    <Container>
      <div className="top__content">
        <div className={'top__content--wrapper'}>
          <div
            className="top__content--left"
            style={{ marginTop: calculationAccordingToApplication ? '14px' : 0 }}
          >
            <div className={'name'}>
              <Tooltip title={description} placement={'top'}>
                <IconButton sx={{ width: 16, height: 16, backgroundColor: 'grey.100' }}>
                  <QuestionMarkIcon sx={{ fontSize: 8 }} />
                </IconButton>
              </Tooltip>
              <div>{name}</div>
            </div>
            {inBasket && (
              <>
                {calculationAccordingToApplication && <TextItem title={'Расчет по заявке'} />}
                {!calculationAccordingToApplication && (
                  <div className={'price-line'}>
                    {inBasket && (
                      <>
                        {calculationAccordingToApplication && (
                          <ValueEmpty title={'Расчет по заявке'} />
                        )}
                        {!calculationAccordingToApplication && (
                          <PriceWithTaxItem
                            taxValue={taxInTargetCurrency}
                            value={basePrice}
                            currency={'Р'}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {service.fields.filter((f) => f.type !== 'hidden').length !== 0 && (
            <div className={'fields-wrapper'}>
              <FieldsMap
                realTimeValidation={true}
                setState={setState}
                fields={service.fields.filter((f) => f.type !== 'hidden')}
                taxes={taxes}
                size={'small'}
                state={state}
                contractorsOptions={contractorsOptions}
                currencies={currencies}
              />
            </div>
          )}
          {isOpen && (
            <div className="properties">
              {properties
                .sort((a, b) => a.ordinal - b.ordinal)
                .map((item, index) => {
                  return <PropertyItem key={index} item={item} />;
                })}
            </div>
          )}
        </div>
        <div className="top__content--right">
          {inBasket && (
            <MButton
              onClick={onChange}
              className={clsx('add-to-card-action', { 'in-card': inCard })}
              variant={'outlined'}
              color={inCard ? 'primary' : 'secondary'}
              fullWidth={true}
            >
              {inCard && 'Убрать из заказа'}
              {!inCard && 'Добавить в мой заказ'}
            </MButton>
          )}
          {!isNeedToCalculate && !inBasket && (
            <MButton
              onClick={onClick}
              className={'add-to-card-action'}
              variant={'outlined'}
              color={'secondary'}
              fullWidth={true}
            >
              {'Рассчитать'}
            </MButton>
          )}
          <MButton
            className={'offer-actions'}
            fullWidth={true}
            variant={'outlined'}
            color={'inherit'}
            onClick={() => null}
            disabled
          >
            {isMobile ? (
              <FindInPageIcon color={'inherit'} sx={{ fontSize: '20px' }} />
            ) : (
              'Детализация'
            )}
          </MButton>
          <MButton
            className={'offer-actions'}
            onClick={() => null}
            fullWidth={true}
            color={'inherit'}
            variant={'outlined'}
            disabled
          >
            {isMobile ? <PrintIcon color={'inherit'} sx={{ fontSize: '20px' }} /> : 'Распечатать'}
          </MButton>
        </div>
      </div>
      {properties.length !== 0 && (
        <MButton
          onClick={() => setIsOpen(!isOpen)}
          variant={'outlined'}
          className={'trigger'}
          color={isOpen ? 'primary' : 'inherit'}
          fullWidth={true}
        >
          <span>
            {isOpen && 'Свернуть'}
            {!isOpen && 'Развернуть'}
          </span>
          {isOpen && <KeyboardArrowUpRoundedIcon sx={{ fontSize: '20px', fill: '#383838' }} />}
          {!isOpen && <KeyboardArrowDownRoundedIcon sx={{ fontSize: '20px', fill: '#383838' }} />}
        </MButton>
      )}
    </Container>
  );
};

export default SimpleAdditionalServiceItem;
