import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CustomCargoParametersItemProps } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/interfaces';
import {
  CustomCargoBaseParameters,
  CustomCargoCalcType,
} from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { checkEmptyInputValue, getformattedFloatNumber } from '../../../helpers';

const CustomCargoBase: React.FC<CustomCargoParametersItemProps> = ({
  customCargoParameters,
  onChangeCustomCargoParameters,
  Position,
  isDeletable,
  onDelete,
  onChangeCargoFieldsFilled
}) => {
  const { t } = useTranslation('Common');

  const isHeightInputInvalid = checkEmptyInputValue(customCargoParameters.baseParameters.height);
  const isLengthInputInvalid = checkEmptyInputValue(customCargoParameters.baseParameters.length);
  const isQuantityInputInvalid = checkEmptyInputValue(customCargoParameters.baseParameters.quantity);
  const isWeightInputInvalid = checkEmptyInputValue(customCargoParameters.baseParameters.weight);
  const isWidthInputInvalid = checkEmptyInputValue(customCargoParameters.baseParameters.width);

  useEffect(() => {
    onChangeCargoFieldsFilled([
      isHeightInputInvalid,
      isLengthInputInvalid,
      isWeightInputInvalid,
      isQuantityInputInvalid,
      isWidthInputInvalid,
    ]);
  }, [
    isHeightInputInvalid,
    isLengthInputInvalid,
    isWeightInputInvalid,
    isQuantityInputInvalid,
    isWidthInputInvalid,
  ]);

  const handleChangeCustomCargoBaseParameters = <T extends keyof CustomCargoBaseParameters>(
    key: T,
    value: CustomCargoBaseParameters[T]
  ) => {
    onChangeCustomCargoParameters({
      ...customCargoParameters,
      baseParameters: {
        ...customCargoParameters.baseParameters,
        [key]: value,
      },
    });
  };

  const handleChangeCustomCargoCalcType = (type: CustomCargoCalcType) => {
    onChangeCustomCargoParameters({
      ...customCargoParameters,
      parametersCargoCalcType: type,
    });
  };

  const handleChangeCustomCargoType = (type: 'base' | 'pallet') => {
    onChangeCustomCargoParameters({
      ...customCargoParameters,
      parametersType: type,
    });
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography variant={'subtitle2'} color={'grey[700]'}>
        {t('Common:CargoPlace')} #{Position}
      </Typography>
      <Box className={'cargo-types'}>
        <Typography component={'span'} variant={'h5'} className={'cargo-types__title'}>
          {t('Common:CalculationType')}
        </Typography>
        <Button size={'small'} variant={'outlined'}>
          {t('Common:CargoPlace')}
        </Button>
        <Button
          size={'small'}
          variant={'outlined'}
          className={'not-selected'}
          color={'inherit'}
          onClick={() => handleChangeCustomCargoCalcType('volume')}
        >
          {t('Common:Consignment')}
        </Button>
        <Typography component={'span'} variant={'h5'} className={'cargo-types__title'}>
          {t('Common:InputType')}
        </Typography>
        <Button size={'small'} variant={'outlined'}>
          {t('Common:Dimensions')}
        </Button>
        <Button
          size={'small'}
          className={'not-selected'}
          variant={'outlined'}
          color={'inherit'}
          onClick={() => handleChangeCustomCargoType('pallet')}
        >
          {t('Common:Pallet')}
        </Button>
      </Box>
      <Box className={'cargo-inputs-block-grid'}>
        <TextField
		  error={isWidthInputInvalid}
		  focused={isWidthInputInvalid}
          className={'cargo-inputs-block__half-mobile width'}
          variant={'standard'}
          value={customCargoParameters.baseParameters.width}
          onChange={(event) =>
            handleChangeCustomCargoBaseParameters('width', getformattedFloatNumber(event.target.value))
          }
          type={'text'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:WidthM')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
		  error={isLengthInputInvalid}
		  focused={isLengthInputInvalid}
          className={'cargo-inputs-block__half-mobile length'}
          variant={'standard'}
          value={customCargoParameters.baseParameters.length}
          onChange={(event) =>
            handleChangeCustomCargoBaseParameters('length', getformattedFloatNumber(event.target.value))
          }
          type={'text'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:LengthM')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
		  error={isHeightInputInvalid}
		  focused={isHeightInputInvalid}
          fullWidth
          variant={'standard'}
          className={'height'}
          value={customCargoParameters.baseParameters.height}
          onChange={(event) =>
            handleChangeCustomCargoBaseParameters('height', getformattedFloatNumber(event.target.value))
          }
          type={'text'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:HeightM')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
		  error={isWeightInputInvalid}
		  focused={isWeightInputInvalid}
          fullWidth
          variant={'standard'}
          className={'weight'}
          value={customCargoParameters.baseParameters.weight}
          onChange={(event) => {
            handleChangeCustomCargoBaseParameters('weight', getformattedFloatNumber(event.target.value));
          }}
          type={'text'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:WeightKg')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
		  error={isQuantityInputInvalid}
		  focused={isQuantityInputInvalid}
          fullWidth
          variant={'standard'}
          className={'seats'}
          value={customCargoParameters.baseParameters.quantity}
          onChange={(event) =>
            handleChangeCustomCargoBaseParameters('quantity', parseInt(event.target.value))
          }
          type={'number'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:SeatsPcs')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {isDeletable && (
        <IconButton
          sx={{
            position: 'absolute',
            right: '-15px',
            top: '-15px',
          }}
          size={'small'}
          onClick={() => onDelete()}
        >
          <CloseIcon sx={{ fontSize: 22 }} />
        </IconButton>
      )}
    </Box>
  );
};

export default CustomCargoBase;
