import palette from '@assets/theme/palette';
import { Link, Typography } from '@material-ui/core';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useDelivery from '../../../useDelivery';

const VideoLink = styled(Link)`
  color: ${palette.light.blue[400]};
  border-bottom: 1px solid transparent;
  transition: 400ms;

  &:hover {
    color: #224179;
    border-bottom: 1px solid ${palette.light.blue[400]};
  }
`;

const linksByLangId = {
	1: 'http://onlogsystem.com/calculator/instruktsiya',
	2: 'https://onlogsystem.com/en/calculator/instruction/'
};

const VideoInstruction: FC = () => {
  const { t } = useTranslation('CustomsCalculator');
  const [settings] = useDelivery().useSettings().useState();

  const { primaryLanguageId } = settings;

  return (
    <Typography variant={'body1'} component={'div'} className={'video-instruction'}>
      <VideoLink
        color={palette.light.blue[400]}
        href={linksByLangId[primaryLanguageId]}
        target="_blank"
        underline={'none'}
      >
        {t('CustomsCalculator:VideoInstruction')}
      </VideoLink>{' '}
      <span>{t('CustomsCalculator:VideoInstructionText')}</span>
    </Typography>
  );
};

export default VideoInstruction;
