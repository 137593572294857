import { orderService } from '@services/requests/orderService';
import { Order } from '@services/requests/orderService/interface';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { RouteResult } from '@services/requests/orderService/types';
import {
  ServicesServicePriceCalculationMutationProps,
  ServicesServicePriceCalculationServiceName,
} from '@services/requests/servicesService/servicesServicesService/interface';
import { useStateSubscriber } from '@settings/core/stateContexts/useContextSubscriber';
import { $error } from '@settings/errorContext';
import { BehaviorSubject } from 'rxjs';

import BASKET_CONSTANTS from './consts';

const LOCALSTORAGE_CART_KEY = '__ON-LOG_CART__';
const $orderId = new BehaviorSubject<string | undefined>(undefined);
const $order = new BehaviorSubject<Order>(BASKET_CONSTANTS.order);
const $productsIds = new BehaviorSubject<string[]>([]);
const $isLoading = new BehaviorSubject<boolean>(false);

/**
 * Инициализация
 */
const init = async (language_id: string, currency_id: string) => {
  const { orderId } = JSON.parse(localStorage.getItem(LOCALSTORAGE_CART_KEY) || `{"orderId": ""}`);

  if (!orderId) {
    const order = $order.getValue();

    $order.next({
      ...order,
      language_id,
      currency_id,
    });
    return;
  }

  try {
    const order = await orderService('').Load('preOrder', orderId);

    $order.next({
      ...order,
    });
    const productsIdsArray = order.products.map((p) => p.id);
    $productsIds.next(productsIdsArray);
    $orderId.next(orderId);
  } catch (e) {
    localStorage.setItem(
      LOCALSTORAGE_CART_KEY,
      JSON.stringify({
        orderId: '',
      })
    );
    $error.next(e);
  }
};

/**
 * Обработчик оформления заказа
 * @param id
 * @param name
 * @param email
 * @param phone
 */

const completeOrder = async (id: string, name: string, email: string, phone: string) => {
  const order = $order.getValue();
  const products = $productsIds.getValue();

  const selectedProducts = order.products.filter((p) => products.includes(p.id));

  const result = await orderService('').SaveOrder({
    ...order,
    products: selectedProducts,
    id: id,
    pre_order_id: order.id,
    customer_id: id,
    customer_name: name,
    customer_email: email,
    customer_phone: phone,
  });

  reInitCart();

  return result;
};

/**
 * Сброс состояния корзины
 */
const reInitCart = () => {
  localStorage.removeItem(LOCALSTORAGE_CART_KEY);

  $orderId.next(undefined);
  $order.next(BASKET_CONSTANTS.order);
  $productsIds.next(BASKET_CONSTANTS.order.products.map((p) => p.id));
  $isLoading.next(false);
};

/**
 * Обработчик сохранения корзины в localStorage
 */
const saveCart = async (order: Order): Promise<Order> => {
  const newOrder = await orderService('').SavePreOrder(order);
  localStorage.setItem(
    LOCALSTORAGE_CART_KEY,
    JSON.stringify({
      orderId: newOrder.id,
    })
  );

  $productsIds.next(newOrder.pre_order_products.map((p) => p.id));
  $orderId.next(newOrder.id);

  return {
    ...newOrder,
    products: newOrder?.pre_order_products || [],
  };
};

/**
 * Сравнение двух продуктов в зависимости от их типов
 * @param a
 * @param b
 */
export function isProductEquals(a: OrderProduct, b: OrderProduct) {
  const { product_type, data } = a;

  if (b.product_type !== product_type) {
    return false;
  }

  switch (product_type) {
    case 'route':
      const newRoute: RouteResult = JSON.parse(b.data);
      const currentRoute: RouteResult = JSON.parse(data);

      const isRouteGroupsEquals = newRoute.route.routes
        .map((r) => r.route.routeId)
        .map((id) => currentRoute.route.routes.map((r) => r.route.routeId).includes(id))
        .reduce((res: boolean, val: boolean): boolean => res && val, true);
      return (
        isRouteGroupsEquals && newRoute.route.routes.length === currentRoute.route.routes.length
      );
    default:
      return false;
  }
}

/**
 * Обработчик добавления товара в корзину
 * @param order
 * @param product
 */
const addProductToCart = (order: Order, product: OrderProduct): Order => {
  const existProduct = order.products.find((p) => isProductEquals(p, product));

  if (!existProduct) {
    return {
      ...order,
      products: [...order.products, product],
    };
  }

  $productsIds.next(order.products.map((p) => p.id));

  return {
    ...order,
    products: order.products.map((p) => {
      if (isProductEquals(p, product)) {
        p.amount += product.amount;
      }

      return p;
    }),
  };
};

/**
 * Обработчик добавления в корзину
 * @param product
 */
const addToCart = async (product: OrderProduct) => {
  const order = $order.getValue();

  $order.next(await saveCart(addProductToCart(JSON.parse(JSON.stringify(order)), product)));
};

/**
 * Обработчик добавления комплекса услуг в корзину
 * @param additionalServices
 * @param sectionName
 * @param serviceParent
 */
const addToCartComplexAdditionalService = async (
  additionalServices: ServicesServicePriceCalculationMutationProps[],
  sectionName: ServicesServicePriceCalculationServiceName,
  serviceParent: ServicesServicePriceCalculationServiceName
) => {
  const newService = additionalServices.reduce(
    (acc: OrderProduct[], i: ServicesServicePriceCalculationMutationProps) => {
      acc = [
        ...acc,
        {
          amount: 1,
          price: i.result?.result.fullPriceInTargetCurrency || 0,
          product_type: 'service',
          token: i.result.token,
          data: JSON.stringify({ service: i, sectionName, serviceParent }),
        },
      ];

      return acc;
    },
    []
  );

  const preOrderService = await orderService('').SavePreOrderGroup(newService);
  return await addToCart(preOrderService);
};

/**
 * Обработчик добавления одной услуги в корзину
 * @param additionalService
 * @param sectionName
 */
const addToCartSingleAdditionalService = async (
  additionalService: ServicesServicePriceCalculationMutationProps,
  sectionName: ServicesServicePriceCalculationServiceName
) => {
  const {
    result: { fullPriceInTargetCurrency },
    token,
  } = additionalService.result;
  const newService: OrderProduct = {
    id: additionalService.serviceId,
    amount: 1,
    price: fullPriceInTargetCurrency || 0,
    product_type: 'service',
    token: token,
    data: JSON.stringify({ service: additionalService, sectionName }),
  };

  return await addToCart(newService);
};

/**
 * Обработчик удаления продукта из корзины
 * @param product
 */
const deleteFromCart = async (product: OrderProduct) => {
  const order = $order.getValue();

  const tmpOrder = {
    ...JSON.parse(JSON.stringify(order)),
    products: order.products.filter((p) => !isProductEquals(p, product)),
  };

  $productsIds.next(tmpOrder.products.map((p: OrderProduct) => p.id));
  $order.next(await saveCart(tmpOrder));

  if (!tmpOrder.products.length) {
    localStorage.removeItem(LOCALSTORAGE_CART_KEY);
  }
};

/**
 * Обработчик очистки корзины
 */
const clearCart = async () => {
  const order = $order.getValue();

  const tmpOrder = {
    ...JSON.parse(JSON.stringify(order)),
    products: [],
  };
  $productsIds.next([]);
  $order.next(await saveCart(tmpOrder));

  if (!tmpOrder.products.length) {
    localStorage.removeItem(LOCALSTORAGE_CART_KEY);
  }
};

/**
 * Обработчик создания временного предзаказа для возможности
 * формирования КП в pdf
 */
const createTempPreOrder = async (product: OrderProduct): Promise<Order> => {
  const { currency_id, language_id } = $order.getValue();
  const newPreOrder = await orderService('').SavePreOrder({
    ...BASKET_CONSTANTS.order,
    currency_id,
    language_id,
    id: '',
    products: [product],
  });

  $productsIds.next(newPreOrder!.pre_order_products.map((p) => p.id) || []);

  return {
    ...newPreOrder,
    products: newPreOrder!.pre_order_products || [],
  };
};

const actions = {
  completeOrder,
  reInitCart,
  saveCart,
  addProductToCart,
  addToCart,
  deleteFromCart,
  clearCart,
  addToCartComplexAdditionalService,
  addToCartSingleAdditionalService,
  init,
  createTempPreOrder,
};

const useBasket = () => {
  const useIsLoading = () => useStateSubscriber<boolean>($isLoading);
  const useOrderId = () => useStateSubscriber<string | undefined>($orderId);
  const useOrder = () => useStateSubscriber<Order>($order);
  const useProductsIds = () => useStateSubscriber<string[]>($productsIds);

  return {
    useIsLoading,
    useOrderId,
    useOrder,
    useProductsIds,
    actions,
  };
};

export default useBasket;
