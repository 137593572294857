import useBaseDataContext from '@context/useBaseDataContext';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  a11yProps,
  getLocalizedNames,
} from '@pages/AdditionalServices/components/TubsContainer/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useWidget from '../../context';
import Container from './StyledComponents';

const TubsContainer = () => {
  const { useTabs, useTabsActive, useAdditionalFormTrees, useEndSearch } = useWidget();
  const { useSettings } = useBaseDataContext();
  const [tabs] = useTabs().useState();
  const setTrees = useAdditionalFormTrees().setState;
  const [activeTab, setActiveTab] = useTabsActive().useState();
  const [settings] = useSettings().useState();
  const setEndSearch = useEndSearch().setState;

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setEndSearch(false);
    setActiveTab(newValue);
    setTrees([]);
  };

  const { t } = useTranslation('TubsContainer');

  if (tabs.length === 0) {
    return (
      <Container>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={0}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={'root-services-tab'}
        >
          <Tab label={t('TubsContainer:Delivery')} {...a11yProps(0)} />
        </Tabs>
      </Container>
    );
  }

  return (
    <Container>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={Number(activeTab)}
        onChange={handleChange}
        aria-label="basic tabs example"
        className={'root-services-tab'}
      >
        <Tab label={t('TubsContainer:Delivery')} {...a11yProps(0)} />
        {tabs.map((service, index) => (
          <Tab
            key={index + 1}
            label={getLocalizedNames(service, settings.primaryLanguageId)}
            {...a11yProps(index + 1)}
          />
        ))}
      </Tabs>
    </Container>
  );
};

export default TubsContainer;
