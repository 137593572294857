import { useBitrixRedirectGuard } from '@components/guards/bitrixUserGuard/useBitrixRedirectGuard';
import BreadCrumbsHOC, { BreadCrumbProps } from '@components/UI/breadcrumbs';
import CardWrapper from '@components/UI/CardWrapper';
import useBaseDataContext from '@context/useBaseDataContext';
import { useYaHit } from '@context/useYaHit';
import { Global } from '@emotion/react';
import { LinearProgress } from '@material-ui/core';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CartAsideBlock from '@pages/Basket/components/CartAsideBlock';
import ServicesListWrapper from '@pages/Basket/components/ServicesList';
import ArrowIcon from '@pages/Basket/icons/ArrowIcon';
import getEnv from '@settings/getEnv';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyBasketPlaceholder } from './EmptyBasketPlaceholder';
import { getCartPriceSummary } from './helpers';
import CartComponentStyledContainer, { SwipeableDrawerStyledContainer } from './StyledComponent';
import useBasket from './useBasket';
import BitrixUserContext from '@context/useBitrixUser';

const SWIPERDRAWERSETTINGS = {
  height: 70,
};

/** *
 * Компонент вывода корзины
 */
const Basket = () => {
  const { useOrder, useIsLoading, actions } = useBasket();
  const { clearCart } = actions;
  const [isLoading] = useIsLoading().useState();
  const [order] = useOrder().useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['CartComponent', 'PageNames', 'Common']);
  const { useBaseData } = useBaseDataContext();
  const [baseData] = useBaseData().useState();
  const { user } = useContext(BitrixUserContext);

  useYaHit({});

  if (baseData === undefined) {
    return (
      <div style={{ minHeight: 500, width: '100%' }}>
        <LinearProgress />
      </div>
    );
  }

  const { currencies } = baseData;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenDrawer(newOpen);
  };

  const handlePdfDownload = () => {
    const { REACT_APP_PDF_VIEW_ENDPOINT } = getEnv();
    window.open(`${REACT_APP_PDF_VIEW_ENDPOINT}/pre-order/${order.id}`, '_blank');
  };

  const breadCrumbs: BreadCrumbProps[] = [
    {
      id: 1,
      name: t('PageNames:MainPage'),
      url: 'widget',
    },
    {
      id: 1,
      name: t('PageNames:MyOrder'),
    },
  ];

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!order.products.length || !user) {
    return (
      <EmptyBasketPlaceholder
        breadCrumbs={breadCrumbs}
        titleText={t('CartComponent:SelectedServices')}
        subTitleText={t('CartComponent:SelectedServicesQuestion')}
        infoText={t('CartComponent:SelectedServicesAction')}
        calculatorBtnText={t('CartComponent:SelectedServiceButton')}
      />
    );
  }

  const summary = {
    ...getCartPriceSummary(order),
    currencyCode: currencies.find((c) => c.id === order.currency_id).code,
  };

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 'max-content',
            overflow: 'visible',
            borderRadius: '20px 20px 0 0',
            WebkitOverflowScrolling: 'touch',
          },
        }}
      />
      <CartComponentStyledContainer component={'section'}>
        <CardWrapper className="services">
          <BreadCrumbsHOC crumbs={breadCrumbs} />
          <Typography variant={'h1'} color={'common.black'} className={'title'}>
            {t('CartComponent:SelectedServices')}
          </Typography>
          <ServicesListWrapper />
          {!isMobile && (
            <Button
              onClick={clearCart}
              variant={'outlined'}
              color={'inherit'}
              className={'reset-cart'}
            >
              {t('CartComponent:EmptyTrash')}
            </Button>
          )}
        </CardWrapper>
        {!isMobile && (
          <div className="aside">
            <CartAsideBlock
              onAddFavoriteClick={() => false}
              onGetPDFClick={handlePdfDownload}
              onQuestionClick={() => false}
              onSendEmailClick={() => false}
              summary={summary}
            />
          </div>
        )}
        {isMobile && (
          <SwipeableDrawer
            anchor="bottom"
            open={openDrawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={SWIPERDRAWERSETTINGS.height}
            disablePortal
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            <SwipeableDrawerStyledContainer
              className={clsx({ '--open': openDrawer, '--ios': iOS })}
            >
              <IconButton
                className={clsx('arrow', { 'arrow--close': openDrawer })}
                size={'small'}
                onClick={toggleDrawer(!openDrawer)}
              >
                <ArrowIcon />
              </IconButton>
              <CartAsideBlock
                onAddFavoriteClick={() => false}
                onGetPDFClick={handlePdfDownload}
                onQuestionClick={() => false}
                onSendEmailClick={() => false}
                summary={summary}
              />
            </SwipeableDrawerStyledContainer>
          </SwipeableDrawer>
        )}
      </CartComponentStyledContainer>
    </>
  );
};

export default Basket;
