import setLocationTextProps from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { getLocationLocalizedNames } from '@services/requests/localizedMessagesService/helpers/getLocationsLocalizedPath';

const setLocationText = (props: setLocationTextProps) => {
  const {
    location,
    setValue,
    primaryLangId,
    secondaryLangId,
    isFrom,
    shippersWarehouse,
    consigneesWarehouse,
    terminal,
  } = props;
  if (!location) {
    setValue('');

    return;
  }

  let newValue = getLocationLocalizedNames(location, primaryLangId, secondaryLangId)[
    secondaryLangId
  ];
  if (location.isWarehouse) {
    newValue = isFrom
      ? `${newValue} (${shippersWarehouse})`
      : `${newValue} (${consigneesWarehouse})`;
    setValue(newValue);

    return;
  }

  if (location.symbolCode === 'ANY') {
    newValue = `${newValue} (${terminal})`;
  }

  if (location.symbolCode !== 'ANY') {
    let { symbolCode, type } = location;
    if (type === 'terminal') {
      symbolCode = `${location.subItems[0].symbolCode}` as any;
    }

    newValue = `${newValue} (${symbolCode})`;
  }

  setValue(newValue);
};

export const getformattedFloatNumber = (value: string) => {
	const onlyNumbersValue = value.replace(/[^0-9\.,]*/g, "");
	const commaReplacedValue = onlyNumbersValue.replace(/,/g, '.');
	const isAlreadyFloat = Array.from(commaReplacedValue).filter(char => char === ".").length > 1;

	let parsedNumber = commaReplacedValue;

	if (isAlreadyFloat) {
		parsedNumber = parseFloat(commaReplacedValue).toString();
	}

	const firstChar = parsedNumber[0];
	if (firstChar === '.') {
		parsedNumber = '0' + parsedNumber;
	}

	return parsedNumber;
};

export const checkEmptyInputValue = <T extends number | string>(value: T) => {
	if (typeof value === "string") {
		return !value.length;
	}

	if (typeof value === "number") {
		return !Number.isFinite(value);
	}
};

export default setLocationText;
